@import 'styles/_theme.scss'

.container
  margin: 24px 20px

.h2
  font-size: 20px
  line-height: 30px
  font-weight: bold
  color: $color-header
  text-wrap: nowrap
  margin: 0 0 20px 0

.h3
  font-size: 16px
  font-weight: bold
  line-height: 22px
  color: #000

.closeBtn
  height: 30px
  width: 30px
  background-color: $color-subheader
  border-radius: 50%
  color: #fff
  font-size: 22px
  font-weight: bold
  border: none
  float: right

.noresult
  margin: 50px auto
  text-align: center
  font-size: 18px
  line-height: 30px
  color: $color-text


@media (max-width: calc($width-desktop - 0.1px))
  .container
    margin: 24px 0

@media (max-width: calc($width-tablet - 0.1px))
  .container
    margin: 20px
