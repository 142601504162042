.dividerline
  height: 1px
  background: #CECECE

.loginErrMsg
  margin-top:5px
  text-align: left
  font-size: 16px
  letter-spacing: 0px
  font-weight: 600
  color: #f00
  opacity: 1