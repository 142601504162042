@import 'styles/_theme.scss'

.bg
  height: 80px
  width: 100%
  max-width: 1480px
  position: fixed
  object-fit: contain
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)
  background-image: linear-gradient(to right, #ab0033 0%, #ab0033 10%, #08487d 90%, #08487d 100%)
  z-index: 299
.box
  height: 80px
  display: flex
  margin: 0
  padding: 15px 20px
  align-items: center
  justify-content: space-between
  width: 100%
  z-index: 300
  transition: all ease-in-out .3s
.dleft
  display: flex
  align-self: flex-start
  align-items: center
  height: 50px
.dcenter
  display: flex
  position: absolute
  right: 220px
  width: 250px
.img
  height: 50px
  width: 138px
  object-fit: contain
  margin-right: 20px
.a
  color: #000
  font-size: 13px
  &:hover
    color: #000
    text-decoration: none
.dright
  display: flex
  justify-content: flex-end
  align-items: center
  height: 50px
  gap: 30px
  text-align: center
  margin-left: 20px
  margin-right: -30px
  label
    text-wrap: nowrap
.menuItem
  display: flex
  flex-direction: row
  align-items: center
  font-size: 16px
  line-height: 22px
  color: #fff
  text-align: center
  opacity: 100%
  letter-spacing: normal
  cursor: pointer
  transition: all ease-in-out .3s
  text-decoration: none
  label
    cursor: pointer
    text-decoration: none
    margin-left: 7px
  &:hover
    color: #fff
    text-decoration: none
    .btnimg
      fill: #fff
  &:active
    color: #fff
    .btnimg
      fill: #fff
.btnimg
  height: 20px
  vertical-align: middle
  fill: #fff
  transition: all ease-in-out .3s
.active
  fill: #fff
  color: #fff
.menuOpen
  color: #064b69
  fill: #064b69

.exceptMobile
  display: block
.onlyMobile
  display: none

@media (max-width: calc($width-desktop - 0.1px))
  .bg
    max-width: 1040px

@media (max-width: calc($width-tablet - 0.1px))
  .bg
    height: 145px
    max-width: 600px
  .box
    height: 145px
  .dleft
    flex-direction: column
    gap: 10px
    // width: 100%
  .dcenter
    display: block
    position: absolute
    width: 100%
    padding: 20px
    right: 0
    top: 65px
  .dright
    float: right
    position: absolute
    right: 20px
    top: 15px
    width: calc(100% - 180px)
    gap: 16px
    margin-right: -16px
  .menuItem
    flex-direction: column
    label
      margin-left: 0


  .exceptMobile
    display: none
  .onlyMobile
    display: block
