@import 'styles/_theme.scss'

.container
  margin: 24px auto
  width: 520px

.h2
  font-size: 22px
  line-height: 26px
  font-weight: bold
  margin-bottom: 23px
  color: $color-header

.menuText
  font-size: 16px
  line-height: 22px
  color: #000000
  font-weight: bold

.link
  font-size: 18px
  line-height: 22px
  margin-bottom: 19px
  text-decoration: underline
  color: $color-subheader
  text-decoration: none
  transition: all ease-in-out .3s
  &:hover
    text-decoration: underline

.info
  font-size: 16px
  line-height: 20px
  font-weight: bold
  color: black

.span
  margin-bottom: 30px

@media (max-width: calc($width-tablet - 0.1px))
  .container
    margin: 24px 20px

@media (max-width: $width-mobile)
  .container
    width: unset
