@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 30px 80px
  // max-width: 1920px
  h3
    font-size: 22px
    line-height: 26px
    font-weight: bold
    margin-bottom: 30px
    color: $color-header

.passwordContainer
  display: flex
  flex-direction: column
  padding: 20px
  background: #fafafa 0% 0% no-repeat padding-box
  border: 1px solid #CECECE
  max-width: 100%
  min-width: 350px
  gap: 20px
  span
    text-align: left
    letter-spacing: 0.6px
    color: $color-black
    font-weight: bold
  .errorMsg
    color: red
    font-size: 12px
    font-weight: normal

.fieldset
  display: flex
  flex-direction: column
  gap: 5px

.focus
  &:focus
    border: 2px solid #006dff !important
  &:focus-visible
    outline: none !important

.form
  display: flex
  flex-direction: column
  margin-top: 30px
  gap: 20px
  label
    text-align: left
    font-size: 16px
    font-weight: bold
    letter-spacing: 0px
  input
    max-width: 100%
    min-width: 350px
    height: 38px
    border: 1px solid $color-grey
    border-radius: 3px
    padding-left: 8px
    font-size: 16px
  Select
    max-width: 100%
    min-width: 350px
    height: 38px
    font-size: 16px
  button
    width: 178px
    height: 38px
    background: $color-interactive
    border: 1px solid $color-interactive
    border-radius: 20px
    color: $color-white
    font-size: 16px
    opacity: 1
    transition: all ease-in-out .3s
    &:hover
      background: #064B69
      border: 1px solid #1185B9
input[type=password]
  padding-bottom: 6px
  min-width: 300px
input[type=token]
  position: absolute
  bottom: 18px
  height: 0.5px
  background: transparent
  border: 0
  outline: none
  z-index: -1
.helpertext
  font-size: 0.7em
  padding: 0
  margin: 0
  position: relative
  top: -6px
  color: #777 !important
  height: 0px
  font-weight: normal !important
.inputG
  border: 1px solid green !important
.inputF
  border: 1px solid red !important
.selectWithError
  border: 1px solid red !important
  border-radius: 30px
.selectGood
  border: 1px solid green !important
  border-radius: 30px
.err_span
  font-size: 15px
  margin: 0
  padding: 0
  color: #f00
.dropdown
  font-size: 16px
  font-family: 'PT Sans', sans-serif
  color: #747474
  max-width: 100%
  min-width: 350px

.submittedSuccessBox
  border: 1px solid #00c202
  width: 100%
  height: 38px
  line-height: 32px
  padding: 0 10px
  margin: 20px 0
  color: #00c202
  background-color: #e5f6e6

.submittedFailedBox
  border: 1px solid #e05852
  width: 100%
  height: 38px
  line-height: 32px
  padding: 0 10px
  margin: 20px 0
  color: #e05852
  background-color: #f7e6e6

.back
  margin-top: 30px
  padding: 0
  cursor: pointer
  width: fit-content
  border: none
  background: none

@media (max-width: calc($width-desktop - 0.1px))
  .box
    padding: 24px 20px

@media (max-width: calc($width-tablet - 0.1px))
  .back
    position: relative

@media (max-width: $width-mobile)
  .box
    padding: 20px

  h3
    font-size: 18px
    margin-bottom: 12px

  .form
    margin-top: 20px
    span
      font-size: 16px

  .submittedSuccessBox
    width: 100%
    font-size: 16px
    height: 100%
    line-height: 1.8em

  .submittedFailedBox
    width: 100%
    font-size: 16px
    height: 100%
    line-height: 1.8em
