body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-family: "PT Sans", sans-serif;
  overflow-x: hidden;
  color: #000000;
}

#bg {
  background: #FAFAFA 0% 0% no-repeat padding-box;
}

p {
  margin: 0;
  padding: 0;
}

label {
  margin-bottom: 0
}

.grecaptcha-badge {
  z-index: 999;
  visibility: hidden;
}