@import 'styles/_theme.scss'
  
.card
  margin: 35px 0 46px 0

.category
  font-size: 16px
  line-height: 18px
  text-align: right
  color: $color-category
  &::first-letter
    text-transform: capitalize
.title
  font-size: 16px
  font-weight: bold
  line-height: 22px
  margin: 9px 0 11px 0
  color: $color-subheader
.description
  font-size: 12px
  line-height: 18px
  color: $color-text
.readmore
  font-size: 12px
  line-height: 18px
  margin-left: 5px
  color: $color-subheader
  text-decoration: underline
  cursor: pointer
  text-wrap: nowrap
