@import 'styles/_theme.scss'

.container
  margin: 24px auto
  width: 520px

:global(.PSL_LABEL)
  margin: 20px 0 0
  font-size: 16px
  line-height: 20px
  color: #707070
  font-weight: bold

:global(.article_p)
  margin: 20px 0 0
  font-size: 16px
  line-height: 20px
  color: #707070

:global(.article_link)
  font-size: 16px
  text-align: center
  color: #4e5ba6
  cursor: pointer

:global(.article_anchor)
  display: block
  position: relative
  top: -80px
  visibility: hidden

:global(.article_reference)
  margin: 16px 0 6px
  font-size: 16px
  line-height: 20px
  word-break: break-all
  color: #707070

:global(.article_source)
  margin-top: 6px
  font-size: 16px
  line-height: 20px
  color: #707070

:global(.article_adbox)
  width: 560px
  margin-left: -20px
  padding: 20px
  border: solid 3px #b18366

.h2
  font-size: 22px
  font-weight: bold
  line-height: 26px
  margin-bottom: 18px
  color: $color-header
.author
  color: #707070
  font-size: 16px
  line-height: 20px
.citation
  color: #707070
  font-size: 16px
  line-height: 20px
  margin-bottom: 12px
.body
  color: #707070
  font-size: 16px
  line-height: 20px
  margin-bottom: 20px

.anchor
  display: block
  position: relative
  top: -80px
  visibility: hidden

@media (max-width: $width-mobile)
  .container
    margin: 24px 20px
    width: unset
