@import 'styles/_theme.scss'

.card
  display: flex
  margin: 20px 0 25px 0
.img
  width: 100px
  margin: 0 15px 1px 0
  border: solid 1px $color-secondary
.title
  font-size: 16px
  font-weight: bold
  line-height: 22px
  margin: 0 0 5px 0
  color: $color-subheader
.description
  font-size: 12px
  line-height: 18px
  color: $color-text
  margin-top: 10px
.readmore
  font-size: 12px
  line-height: 18px
  text-align: right
  color: $color-subheader
  text-decoration: underline
  cursor: pointer
  text-wrap: nowrap
