@import 'styles/_theme.scss'

.container
  display: flex
  flex-direction: column
.loadingContainer
  display: flex
  flex-direction: column
.loginInstrucText
  text-align: left
  font-size: 24px
  line-height: 27px
  letter-spacing: 0px
  font-weight: 600
  color: #000000
  opacity: 1
.loginInput
  border: 1px solid #cecece
  line-height: 40px
  padding: 0 10px !important
  margin: 5px 0 10px
  font-size: 16px
  letter-spacing: 0px
  width: 100%
  color: #6E6E6E
  &:disabled
    background: #e9ecef
.loginBtn
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #3b78c3
  background: #3b78c3
  border-radius: 20px
  width: 111px
  color: #fff
  height: 38px
  font-size: 16px
  margin: 20px 0 0 0
  opacity: 1
  transition: all ease-in-out .3s
  &:hover
    background: #064B69
    border: 1px solid #1185B9
.loginCaptionText
  font-size: 16px
.loginBtn2
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #3b78c3
  color: #3b78c3
  border-radius: 20px
  width: 155px
  align-self: center
  background: #fff
  font-size: 16px
  height: 38px
  padding: 0 10px
  box-sizing: content-box
  opacity: 1
  transition: all ease-in-out .3s
  &:hover
    color: #FFF
    background: #3b78c3
    border: 1px solid #3b78c3
.disableInput
  opacity: 0.5

.loginPasswordLink
  font-size: 16px
  text-decoration: none
  cursor: pointer
  color: #3b78c3
  transition: all ease-in-out .3s
  &:hover
    text-decoration: none
    color: #3b78c3
  &:visited
    color: #3b78c3
