@import 'styles/_theme.scss'

.container
  width: 440px
  // flex: 2 314px
  position: fixed
  background-color: #f4f7fb
  border-right: solid 2px #e5e4e3
  transition: all ease-in-out .3s
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.content
  min-height: 280px
  // padding: 26px 20px
  padding: 30px 0 50px
  overflow: auto
  position: relative
  height: calc(100vh - 80px)
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
  &:not(:last-child)
    border-bottom: solid 2px #e5e4e3

.menu
  width: 100%
  margin: 0
  padding: 8px 20px 11px 20px
  line-height: 22px
  background-color: rgba(32, 91, 140, 0.15)

.menuC
  width: 100%
  margin: 0 0 4px
  padding: 8px 20px
  background-color: rgba(59, 120, 195, 0.1)

.menuTitle
  font-size: 16px
  line-height: 22px
  color: $color-header

.menuText
  font-size: 16px
  line-height: 22px
  color: $color-header

.link
  font-size: 16px
  line-height: 22px
  margin-bottom: 19px
  text-decoration: underline
  color: $color-subheader
  transition: all ease-in-out .3s
  &:hover
    text-decoration: none

.moreText
  font-size: 16px
  line-height: 22px
  float: right
  text-decoration: underline
  color: $color-subheader
  cursor: pointer
  transition: all ease-in-out .3s
  &:hover
    text-decoration: none

.span
  font-size: 16px
  line-height: 22px
  margin-bottom: 19px
  color: $color-header

.title
  font-size: 12px
  line-height: 18px
  margin: 6px 0
.info
  font-size: 12px
  line-height: 18px
  margin-top: 6px
  color: $color-header

@media (max-width: calc($width-desktop - 0.1px))
  .container
    margin-top: -24px

@media (max-width: calc($width-tablet - 0.1px))
  .container
    display: none
    flex: none
    width: 0
    opacity: 0
