@import '../../styles/_theme.scss'

.loginModal
  background: #fff
  box-shadow: 5px 5px 20px #0000001A
  max-width: 535px
  width: 100%
  min-height: 344px
  padding: 24px 20px
  display: flex
  flex-direction: column
.closeModal
  align-self: flex-end
  cursor: pointer

.disabledButton
  opacity: 0.5

.loginCaption2Text
  font-size: 18px
  text-align: center
  margin-bottom: 10px
.dividerline
  height: 1px
  background: #CECECE

.loginBtn2
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #3b78c3
  color: #3b78c3
  border-radius: 20px
  width: 155px
  align-self: center
  background: #fff
  font-size: 16px
  height: 38px
  padding: 0 10px
  box-sizing: content-box
  opacity: 1
  transition: all ease-in-out .3s
  &:hover
    color: #FFF
    background: #3b78c3
    border: 1px solid #3b78c3
.disableInput
  opacity: 0.5

.loginPasswordLink
  width: fit-content
  border: none
  background-color: unset
  text-align: left
  font-size: 16px
  margin: 20px 0 10px 0
  padding: 0
  text-decoration: none
  cursor: pointer
  color: $color-interactive
  transition: all ease-in-out .3s
  &:hover
    text-decoration: none
    color: $color-interactive2
  &:visited
    color: $color-interactive2

.circularProgress
  position: fixed
  left: 45%
  top: 50%
