@import 'styles/_theme.scss'

.container
  margin: 24px auto 30px auto
  width: 520px

.cardHeader
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 15px

.category
  font-size: 12px
  line-height: 18px
  text-align: right
  color: $color-category

.h2
  font-size: 22px
  line-height: 26px
  font-weight: bold
  margin-bottom: 23px
  color: $color-header

.imgAd
  width: 100%

.button
  width: 100%
  height: 36px
  border: none
  background-color: #3c78c4
  font-size: 16px
  line-height: 22px
  font-weight: bold
  color: #fff
  &:hover
    color: #fff

.ad_link
  font-size: 12px
  line-height: 18px
  text-align: center
  color: $color-subheader
  text-decoration: underline
  cursor: pointer

.ad_anchor
  display: block
  position: relative
  height: 0
  top: 0
  visibility: hidden

.iframe
  width: 100%
  height: 460px
  border: none
  overflow: scroll

  // CTA Ad
.title
  font-size: 16px
  font-weight: bold
  line-height: 22px
  margin: 0 0 10px 0
  color: $color-subheader
.description
  font-size: 12px
  line-height: 18px
  color: $color-text
.readmore
  font-size: 12px
  line-height: 18px
  margin-left: 5px
  color: $color-subheader
  text-decoration: underline
  cursor: pointer
  text-wrap: nowrap

@media (max-width: calc($width-tablet - 0.1px))
  .container
    width: 560px

@media (max-width: $width-mobile)
  .container
    margin: 24px 20px
    width: unset
  .iframe
    height: 368px
