@import 'styles/_theme.scss'

.card
  display: flex
  margin: 20px 0
.img
  width: 100px
  margin: 0 15px 1px 0
  border: solid 1px $color-secondary
.title
  font-size: 16px
  font-weight: bold
  line-height: 22px
  margin: 0 0 10px 0
  color: $color-subheader
.description
  font-size: 12px
  line-height: 18px
  color: $color-text
.readmore
  font-size: 12px
  line-height: 18px
  margin-left: 5px
  color: $color-subheader
  text-decoration: underline
  cursor: pointer
  text-wrap: nowrap

@media (max-width: calc($width-desktop - 0.1px))
  .card
    &:last-of-type
      margin-bottom: 0
